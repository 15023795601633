// Colors
// Lighter background colors
//

@each $name, $color in $theme-color-scheme {
	.bg-#{$name}-25 {
		background: mix($color, $white, 25%);
	}
	.bg-#{$name}-50 {
		background: mix($color, $white, 50%);
	}
	.bg-#{$name}-75 {
		background: mix($color, $white, 75%);
	}
}
