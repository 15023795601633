//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.

// Color Scheme
// Uncomment the variables below and change the hex values.

$primary: #34649d;
$primary-2: #94c6e6;
$primary-3: #969696;
$primary-4: #f2f2f2;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
	"primary": $primary,
	"primary-2": $primary-2,
	"primary-3": $primary-3,
	"primary-4": $primary-4,
);

$theme-colors: (
	"primary": $primary,
	"primary-2": $primary-2,
	"primary-3": $primary-3,
	"primary-4": $primary-4,
);

// Color Modifications

// Sizes
$logo-width: 16rem;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: "Open Sans", sans-serif;
$font-family-serif: "Arvo", serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base: $font-family-sans-serif;
$fa-font-path: "../webfonts";

// stylelint-enable value-keyword-case

$font-size-base: 1.125rem;
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * 0.875;

//$font-weight-lighter:         lighter;
//$font-weight-light:           300;
//$font-weight-normal:          400;
//$font-weight-bold:            700;
//$font-weight-bolder:          bolder;

//$font-weight-base:            $font-weight-normal;
//$line-height-base:            1.5;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

//$headings-margin-bottom:      $spacer / 2;
$headings-font-family: $font-family-serif;
//$headings-font-weight:        500;
$headings-line-height: 1.1;
//$headings-color:              null;

//$display1-size:               6rem;
//$display2-size:               5.5rem;
//$display3-size:               4.5rem;
//$display4-size:               3.5rem;

//$display1-weight:             300;
//$display2-weight:             300;
//$display3-weight:             300;
//$display4-weight:             300;
//$display-line-height:         $headings-line-height;

$lead-font-size: $font-size-base * 1.25;
//$lead-font-weight:            300;

$small-font-size: 80%;

//$text-muted:                  $gray-600;

//$blockquote-small-color:      $gray-600;
//$blockquote-small-font-size:  $small-font-size;
//$blockquote-font-size:        $font-size-base * 1.25;

//$hr-border-color:             rgba($black, .1);
//$hr-border-width:             $border-width;

//$mark-padding:                .2em;

//$dt-font-weight:              $font-weight-bold;

//$kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25);
//$nested-kbd-font-weight:      $font-weight-bold;

//$list-inline-padding:         .5rem;

//$mark-bg:                     #fcf8e3;

//$hr-margin-y:                 $spacer;

// Navbar

//$navbar-padding-y:                  $spacer / 2;
//$navbar-padding-x:                  $spacer;

//$navbar-nav-link-padding-x:         .5rem;

//$navbar-brand-font-size:            $font-size-lg;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
//$nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2;
//$navbar-brand-height:               $navbar-brand-font-size * $line-height-base;
//$navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) / 2;

//$navbar-toggler-padding-y:          .25rem;
//$navbar-toggler-padding-x:          .75rem;
//$navbar-toggler-font-size:          $font-size-lg;
//$navbar-toggler-border-radius:      $btn-border-radius;

$navbar-dark-color: rgba($white, 0.75);
$navbar-dark-hover-color: rgba($white, 1);
$navbar-dark-active-color: rgba($white, 1);
//$navbar-dark-disabled-color:        rgba($white, .25);
//$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
//$navbar-dark-toggler-border-color:  rgba($white, .1);

//$navbar-light-color:                rgba($black, .5);
//$navbar-light-hover-color:          rgba($black, .7);
//$navbar-light-active-color:         rgba($black, .9);
//$navbar-light-disabled-color:       rgba($black, .3);
//$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
//$navbar-light-toggler-border-color: rgba($black, .1);

//$navbar-light-brand-color:                $navbar-light-active-color;
//$navbar-light-brand-hover-color:          $navbar-light-active-color;
//$navbar-dark-brand-color:                 $navbar-dark-active-color;
//$navbar-dark-brand-hover-color:           $navbar-dark-active-color;
