// Sizing

.logo-xs {
	width: 100%;
	max-width: $logo-width * 0.25;
}

.logo-sm {
	width: 100%;
	max-width: $logo-width * 0.5;
}

.logo-md {
	width: 100%;
	max-width: $logo-width * 0.75;
}

.logo {
	width: 100%;
	max-width: $logo-width;
}

.logo-lg {
	width: 100%;
	max-width: $logo-width * 1.5;
}

.logo-xl {
	width: 100%;
	max-width: $logo-width * 2;
}
