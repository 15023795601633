// 
// 
// list-group.scss
//
//

.list-group-item{
  &.row{
    display: flex;
    padding-left: 0;
    padding-right: 0;
    transition: color $transition-time ease;
    h5{
      transition: color $transition-time ease;
    }
    &:hover{
      background: none;
      color: theme-color('primary');
      h5{
        color: theme-color('primary');
      }
    }
  }
  &.list-group-item-action{
    transition: background-color $transition-time ease;
  }
}

.list-group-flush{
  &:last-child{
    .list-group-item{
      &.row{
        &:last-child{
          border-bottom: $border-width solid $border-color;
        }
      }
    }
  }
  &:first-child{
    .list-group-item{
      &:first-child{
        border-top: $border-width solid $border-color;
      }
    }
  }
}