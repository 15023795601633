// 
// 
// headers.scss
//
//

.header-inner{
  padding: 0;
  display: block;
  .container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@include media-breakpoint-up(md){
  .header-desktop-app{
    .row:last-of-type{
      margin-bottom: -$spacer*15;
      z-index: 2;
      position: relative;
    }
    +section{
      padding-top: $spacer*15;
    }
  }
}