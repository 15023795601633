.checkmark-wrapper {
	width: 100px;
	margin: 2em auto 0;
}

.checkmark {
	stroke: $primary-2;
	stroke-dashoffset: 745.74853515625;
	stroke-dasharray: 745.74853515625;
	animation: dash 2s ease-in forwards 1;
}

@keyframes dash {
	0% {
		stroke-dashoffset: 745.74853515625;
	}
	100% {
		stroke-dashoffset: 0;
	}
}
