.avatar {
	-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
	filter: grayscale(100%);
}

.avatar-author {
	display: flex;
	h6 {
		margin-bottom: 0;
	}
	h5 {
		margin-bottom: 0;
		line-height: 1.25rem;
	}
}
