// Width
//
// Control an elements min-width in vw units using the class .min-width-#vw with a value of 10 - 90

@include media-breakpoint-up(md) {
	@for $i from 1 through 10 {
		$width: ($i * 10);
		.min-vw-md-#{$width} {
			min-width: #{$width}vw;
		}
	}
}

@include media-breakpoint-up(lg) {
	@for $i from 1 through 10 {
		$width: ($i * 10);
		.min-vw-lg-#{$width} {
			min-width: #{$width}vw;
		}
	}
}
